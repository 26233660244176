import React from 'react';
import AuditorLogo from '../../assets/Auditor Logo.png';
import ConverterLogo from '../../assets/File_Converter_1.png';

const containerStyle = {
    //   padding: '20px',
    //   border: '1px solid #ccc',
    //   borderRadius: '5px',
    //   backgroundColor: '#f9f9f9',
    fontFamily: 'Arial, sans-serif',
    margin: '20px 0',
};

const saveProjectStyle = {
    color: '#d9534f',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
};

const projectInstructionsStyle = {
    color: '#d9534f',
    fontSize: '16px',
    lineHeight: '1.5',
    textAlign: 'center',
};

const linkStyle = {
    color: '#0275d8',
    textDecoration: 'none',
    fontWeight: 'bold',
};

const toolsWithSave = ['wsud', 'osds4vips', 'auditor'];

const SaveProjectMessage = ({ tool }) => {
    return (
        <>
            {!toolsWithSave.includes(tool) ? (
                <div style={{ ...containerStyle, ...saveProjectStyle }}>
                    Save Project Features Coming Soon!
                </div>
            ) : tool === 'auditor' ? (
                <div className="d-flex align-items-center justify-content-between w-100 px-5">
                    <img src={AuditorLogo} alt="Auditor Logo" style={{ height: '90px' }} />
                    <img src={ConverterLogo} alt="Converter Logo" style={{ height: '90px' }} />
                </div>
            ) : (
                <div style={{ ...containerStyle, ...projectInstructionsStyle }}>
                    You can save this project via the Save Project Button at the bottom of this
                    page. Go to the Project History Tab on the User Portal to continue working on it
                    at any point. For older SUS files, please contact Mircea at{' '}
                    <a href="mailto:mircea@cleanstormwater.com.au" style={linkStyle}>
                        mircea@cleanstormwater.com.au
                    </a>
                </div>
            )}
        </>
    );
};

export default SaveProjectMessage;
