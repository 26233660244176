import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function SedbasinTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
            height: '70px',
        },
        tableRow_result: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
        columnTitle: {
            flex: 1,
            textAlign: 'center',
            fontSize: 9,
            borderWidth: 1,
            borderStyle: 'solid',
            color: 'red',
            paddingTop: 20,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.columnTitle}>Device Name</Text>
                    <Text style={styles.columnTitle}>Surface Area (m²)</Text>
                    <Text style={styles.columnTitle}>EDD height (mm)</Text>
                    <View style={{ flex: 2, flexDirection: 'column' }}>
                        <Text style={styles.columnTitle}>Permanent Pool</Text>
                        <Text style={styles.columnTitle}>Average Depth (m)</Text>
                        <Text style={styles.columnTitle}>Volume (m³)</Text>
                    </View>
                    <View style={{ flex: 2, flexDirection: 'column' }}>
                        <Text style={styles.columnTitle}>Exfiltration</Text>
                        <Text style={styles.columnTitle}>Rate (mm/hr)</Text>
                        <Text style={styles.columnTitle}>Outflow (L/s)</Text>
                    </View>
                    <Text style={styles.columnTitle}> Notional Detention Time (hrs)</Text>
                    <Text style={styles.columnTitle}>Equivalent Pipe Diameter (mm)</Text>
                </View>
                {nodeInfo.map((node) => {
                    return (
                        <View style={styles.tableRow_result} key={node['Node Name']}>
                            <Text style={styles.tableCol}>{node['Node Name'] || 'N/A'}</Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Surface Area (square metres)'
                                ]
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Surface Area (square metres)'
                                ]
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <View style={{ flex: 2, flexDirection: 'column' }}>
                                <Text style={styles.tableCol}>
                                    {node[
                                        'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                    ]
                                        ? stringOrNumber(
                                              +node[
                                                  'Storage and Infiltration Properties - Permanent Pool Volume (cubic metres)'
                                              ],
                                          )
                                        : 'N/A'}
                                </Text>
                                <Text style={styles.tableCol}>
                                    {node['Storage and Infiltration Properties - Initial Volume']
                                        ? stringOrNumber(
                                              node[
                                                  'Storage and Infiltration Properties - Initial Volume'
                                              ],
                                          )
                                        : 'N/A'}
                                </Text>
                            </View>
                            <View style={{ flex: 2, flexDirection: 'column' }}>
                                <Text style={styles.tableCol}>
                                    {node[
                                        'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                    ] !== undefined &&
                                    node[
                                        'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                    ] !== null
                                        ? stringOrNumber(
                                              +node[
                                                  'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                              ],
                                          )
                                        : 'N/A'}
                                </Text>
                                <Text style={styles.tableCol}>
                                    {node['Total exfiltration outflow (L/s)'] !== undefined &&
                                    node['Total exfiltration outflow (L/s)'] !== null
                                        ? stringOrNumber(+node['Total exfiltration outflow (L/s)'])
                                        : 'N/A'}
                                </Text>
                            </View>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Notional Detention Time (hrs)']
                                    ? stringOrNumber(
                                          +node[
                                              'Outlet Properties - Notional Detention Time (hrs)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Equivalent Pipe Diameter (mm)']
                                    ? stringOrNumber(
                                          node['Outlet Properties - Equivalent Pipe Diameter (mm)'],
                                      )
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default SedbasinTemplate;
